@import '../../../styles/basics';

.icon-stat-link-wrapper {
  text-decoration: none !important;
}
.icon-stat {
  padding: 15px 0;

  @include media-breakpoint-up(md) {
    padding: 20px 20px 20px 0;
  }

  :global {
    .image-container {
      flex-shrink: 0;
      padding: 0;

      img {
        width: 100%;
        max-width: 64px;
        @include media-breakpoint-up(md) {
          height: 64px;
        }
      }
    }
  }

  > div {
    margin-left: 16px;

    h4 {
      margin-top: -2px;

      @include media-breakpoint-up(md) {
        margin-top: -8px 0 8px 0;
      }
    }

    :global {
      .header-one {
        line-height: 0.8;
        white-space: nowrap;
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
      margin-top: 8px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
  }
}
