@import '../../../styles/basics';

.chart-donut {
  // need to specify height for mobile flex display
  height: 70px;
  display: flex;

  :global {
    .CircularProgressbar {
      width: 70px;
      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 170px;
      }

      .CircularProgressbar-path {
        stroke-linecap: butt;
        transition: stroke-dashoffset 1.5s ease 0s;
      }

      .CircularProgressbar-text {
        font-weight: $font-weight-bold;
        // FIXME - does not correspond to actual html font-size, there is some
        // sneaky scaling going on here inside the CircularProgressbar component
        font-size: 20px;
        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }
    }
  }

  &__progress--turquoise {
    :global {
      .CircularProgressbar-path {
        stroke: $turquoise-1000;
      }

      .CircularProgressbar-text {
        fill: $turquoise-1000;
      }

      .CircularProgressbar-trail {
        stroke: $biscuit-600;
      }
    }
  }

  &__title {
    padding-top: 22px;
    padding-left: 10px;
    // custom typographical element only shown on over desktop display
    &-desktop {
      margin-top: 20px;
      font-size: 22px;
      display: none;
    }
    &-mobile {}
  }
  @include media-breakpoint-up(md) {
    text-align: center;
    height: auto;
    display: block;

    &__title {
      &-desktop {
        display: block;
      }
      &-mobile {
        display: none;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &__title {
      padding: 0;
    }
  }
}
