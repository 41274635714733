@import '../../../styles/basics';

.impact-text {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  overflow: hidden;

  &__message {
    max-width: 510px;
    width: 100%;
    background-color: $turquoise-100;
    border: 1px solid $turquoise-100;
    border-radius: 4px;
    display: flex;
  }

  &__image {
    min-width: 100px;
    min-height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-left: -1px;
  }

  &__icon {
    min-width: 24px;
    margin: 18px 0 18px 12px;
    width: 24px;
    height: 23px;

    path {
      fill: $turquoise-1000;
    }
  }

  &__text {
    color: $turquoise-1000;
    text-align: left;
    padding: 12px;
    font-weight: $font-weight-bold;
    font-size: 14px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    line-height: $line-height-large;
  }

  &--emergency {
    .impact-text__message {
      background-color: $slate-100;
      border-color: $slate-100;
    }

    .impact-text__icon {
      path {
        fill: $black;
      }
    }

    .impact-text__text {
      color: $black;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: -10px;

    &__message {
      align-items: center;
    }

    &__image {
      min-width: 120px;
      min-height: 112px;
    }

    &__icon {
      margin: auto 0 auto 26px;
      height: 100%;
    }

    &__text {
      padding: 18px 26px;
      font-size: 16px;
    }
  }
}
