@import '../../../../styles/basics';
@import '../../../../styles/mixins/responsive-image';
@import '~bootstrap/scss/mixins/grid';

.bb-our-focus {
  @include bb-with-background-padding();

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    @include make-col(6);
    margin-bottom: 12px;
    padding: 0 6px;
  }

  :global(.item-with-image-hoverable-small) {
    // Configure padding-top to avoid CLS issues.
    // TODO: Change it when new solution will be available for picture tag.
    :global(.item) {
      &:hover,
      &:focus-visible {
        h3 {
          text-decoration: underline;
        }
      }
      @media all and (min-resolution: 1x) {
        img {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
        }
        // 185x127 image. 127 / 185 * 100 ~ 68.65
        padding-top: 68.65%;
      }

      @media all and (min-resolution: 2x) {
        // 360x220 image. 220 / 360 * 100 ~ 61.11
        padding-top: 61.11%;
      }

      @media all and (min-width: 480px) and (min-resolution: 1x) {
        // 360x220 image. 220 / 360 * 100 ~ 61.11
        padding-top: 61.11%;
      }

      @media all and (min-width: 480px) and (min-resolution: 2x) {
        // 400x275 image. 275 / 400 * 100 ~ 68.75
        padding-top: 68.75%;
      }

      @media all and (min-width: 768px) and (min-resolution: 1x) {
        // 400x275 image. 275 / 400 * 100 ~ 68.75
        padding-top: 68.75%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__item {
      @include make-col(4);
    }
  }
}
