@import '../../../../styles/basics';

.two-columns-layout {
  position: relative;

  @include media-breakpoint-up(lg) {
    display: flex;
    margin: 60px auto;
  }

  :global {
    section.main {

      @include media-breakpoint-up(lg) {
        flex-basis: 66.6666%;
        padding-right: 64px;

        .bb:first-child {
          margin-top: 0;
        }
      }
    }

    section.sidebar {
      @include media-breakpoint-up(lg) {
        width: 33.3333%;
      }

      &.sucked-up {
        @include media-breakpoint-up(lg) {
          height: 0;
          position: relative;
          top: -226px;

          p,
          .header-two {
            color: $white;
          }
        }
      }
    }
  }

  :global(.bb) > :global(.container) {
    padding-left: 0;
    padding-right: 0;
  }
}
