@import '../../../../styles/basics';

.bb-three-tile {
  &:global(.bb) {
    @include bb-standalone-spacing-new();
  }

  :global {
    .bb-three-tile-heading {
      text-align: center;
      color: $black;
    }

    .bb-three-tile-items {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include media-breakpoint-up(sm) {
        gap: 16px 18px;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }
  }

  // Adjustments for Activism page type.
  &:global(.variant-activism) {
    &:global(.bb) {
      @include bb-activism-spacing();
    }

    &:global(.behaviour-settings__background-color--yellow) {
      position: relative;
      padding-top: 48px;
      padding-bottom: 48px;

      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $yellow-800;
        border-radius: 24px;
        z-index: -1;
      }

      @include media-breakpoint-up(sm) {
        &:before {
          left: 15px;
          right: 15px;
        }
      }

      @include media-breakpoint-up(lg) {
        padding-top: 88px;
        padding-bottom: 88px;

        &:before {
          border-radius: 40px;
        }
      }
    }

    :global {
      .bb-three-tile-heading {
        font-size: 32px;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
          font-size: 60px;
          margin-bottom: 64px;
        }
      }

      .bb-three-tile-items {
        @include media-breakpoint-up(sm) {
          gap: 16px;
        }
      }
    }
  }
}
