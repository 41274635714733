@import '../../../../styles/basics';

.bb-impact-timeline {
  @include bb-standalone-spacing();

  :global(.container) {
    position: relative;
  }

  h2 {
    @include media-breakpoint-up(md) {
      margin-bottom: 60px !important;
    }
  }

  &__timeline {
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 36px;
    height: 3px;
    background: $slate-200;
    z-index: -1;
    @include media-breakpoint-up(md) {
      bottom: 38px;
    }
  }

  &__end,
  &__start {
    background: $white;
    position: absolute;
    height: 36px;
    width: 36px;
    border: 3px solid $slate-200;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: 120;
  }

  &__end {
    right: 0;
    bottom: -15px;
  }

  &__start {
    left: 0;
    bottom: -15px;
  }

  :global(.item) {
    text-align: center;
    position: relative;
    padding-bottom: 15px;

    @include media-breakpoint-up(md) {
      min-height: 155px;
      padding-right: 60px;
      height: 100%;
    }

    :global(.image) {
      margin-bottom: 8px;
      position: relative;
      @include media-breakpoint-up(md) {
        float: left;
      }

      img {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        display: table;
        margin: 0 auto;
      }

      :global(.popover) {
        background: none;
        opacity: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        -webkit-transition: opacity 0.3s;
        -moz-transition: opacity 0.3s;
        -ms-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        transition: opacity 0.3s;
        cursor: pointer;
      }

      &:hover {
        .popover {
          background: url('/static/icons/plus.svg') no-repeat center center $turquoise-1200;
          opacity: 0.8;
        }
      }
    }

    :global(.status) {
      font-size: 14px;
      color: $gray-1000;
      margin-bottom: 8px;
      @include media-breakpoint-up(md) {
        text-align: left;
        margin-left: 100px;
      }

      &:global(.active) {
        color: $pink-800;
      }
    }

    :global(.title) {
      font-weight: $font-weight-bold;
      font-size: 18px;
      color: $slate-1200;
      max-width: 240px;
      margin: 0 auto;
      @include media-breakpoint-up(md) {
        text-align: left;
        margin-left: 100px;
      }
    }

    :global(.short-description) {
      font-size: 14px;
      color: $slate-1200;
      max-width: 240px;
      margin: 0 auto;
      @include media-breakpoint-up(md) {
        text-align: left;
        margin-left: 100px;
      }
    }

    :global(.cta) {
      margin-top: 12px;
      margin-right: 0;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    :global(.spot) {
      position: relative;
      margin: 0 auto;
      height: 64px;
      width: 36px;
      @include media-breakpoint-up(md) {
        position: absolute;
        left: 26px;
        bottom: -42px;
        height: auto;
        top: 88px;
      }

      :global(.border) {
        position: absolute;
        left: 49%;
        top: 2px;
        bottom: 6px;
        width: 1px;
        background: $slate-200;
        z-index: 49;
      }

      :global(.circle) {
        background: $white;
        position: absolute;
        height: 36px;
        width: 36px;
        left: 0;
        bottom: 6px;
        border: 3px solid $slate-200;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        z-index: 50;

        img {
          width: 20px;
          height: 20px;
          margin: 5px 0 0 5px;
        }
      }

      :global(.date) {
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: $slate-1200;
        position: absolute;
        bottom: -14px;
        left: -37px;
        display: block;
        width: 120px;
      }
    }
  }

  :global(.slick-slider) {
    @include media-breakpoint-up(md) {
      padding-left: 60px;
    }

    :global(.slick-list) {
      :global(.slick-track) {
        display: flex;
        height: 100%;
        @include media-breakpoint-up(md) {
          padding-bottom: 60px;
        }

        :global(.slick-slide) {
          height: auto;

          & > div {
            height: 100%;
          }
        }
      }
    }

    :global(.slick-arrow) {
      background: url('/static/icons/carousel-button.svg') no-repeat;
      width: 44px;
      height: 44px;
      bottom: -5px;
      top: auto;
      z-index: 300;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      @include media-breakpoint-up(md) {
        bottom: -2px;
      }

      &:before {
        display: none;
      }

      &:global(.slick-prev) {
        left: 0;
        -webkit-transform: rotate(180deg) translateY(22px);
        -moz-transform: rotate(180deg) translateY(22px);
        -ms-transform: rotate(180deg) translateY(22px);
        -o-transform: rotate(180deg) translateY(22px);
        transform: rotate(180deg) translateY(22px);

        &:hover {
          opacity: 0.9;
        }
      }

      &:global(.slick-next) {
        right: 0;
        -webkit-transform: rotate(360deg) translateY(-22px);
        -moz-transform: rotate(360deg) translateY(-22px);
        -ms-transform: rotate(360deg) translateY(-22px);
        -o-transform: rotate(360deg) translateY(-22px);
        transform: rotate(360deg) translateY(-22px);

        &:hover {
          opacity: 0.9;
        }
      }

      &:global(.slick-disabled) {
        background: url('/static/icons/carousel-button-inactive.svg') $white;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        cursor: default;
      }
    }
  }

  :global(.popup) {
    background: none;
    position: absolute;
    left: 24px;
    top: 0;
    right: 24px;
    bottom: 0;
    display: flex;
    width: auto;
    height: 250px;

    :global(.body) {
      background: $white;
      margin: auto;
      max-width: 780px;
      z-index: 6000;
      box-shadow: $shadow2;
      position: relative;

      :global(.close-btn) {
        background: url('/static/icons/close-green.svg') no-repeat center center $turquoise-1000;
        position: absolute;
        cursor: pointer;
        right: -22px;
        top: -22px;
        height: 44px;
        width: 44px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        z-index: 4000;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        &:hover {
          opacity: 0.9;
        }
      }

      :global(.left) {
        float: left;
        width: 270px;
        height: 250px;
      }

      :global(.content) {
        margin-left: 306px;

        :global(.title) {
          margin: 32px 32px 10px 0;
        }

        :global(.description) {
          font-size: 14px;
          margin: 0 32px 24px 0;
        }
      }
    }
  }
}
