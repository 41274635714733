@import '../../../styles/basics';
@import '../../../styles/mixins/buttons';

.activism-tile-item {
  flex-grow: 1;
  margin: 0;
  padding: 0;

  &:before {
    content: none;
  }

  // Use hover only when it is supported by a device.
  @media (hover: hover) and (pointer: fine) {
    a {
      &:hover,
      &:focus-visible {
        text-decoration: none;

        :global([data-component="button-primary"]) {
          @include button-primary-hover;
          @include button-with-arrow-hover;
        }

        :global(.text-button--default) {
          @include text-button-default-hover;
          @include text-button-with-arrow-hover;
        }

        :global(.text-button--light) {
          @include text-button-light-hover;
          @include text-button-with-arrow-hover;
        }

        img {
          transform: scale(1.03);
        }
      }
    }
  }

  :global {
    .activism-tile-item__container {
      display: block;
      position: relative;
    }

    .activism-tile-item__image {
      aspect-ratio: 330/320;
      position: relative;
      overflow: hidden;
      border-radius: 4px;

      @include media-breakpoint-up(lg) {
        aspect-ratio: 414/554;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .activism-tile-item__info {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      border-radius: 4px;
      padding: 16px;
      gap: 16px;
      background: linear-gradient(19.8deg, rgba(33, 36, 49, 0.78) 10.28%, rgba(33, 36, 49, 0) 94.43%);

      @include media-breakpoint-up(sm) {
        padding: 24px;
        gap: 24px;
      }

      @include media-breakpoint-up(lg) {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.54) 0%, rgba(0, 0, 0, 0.54) 33.33%, rgba(0, 0, 0, 0) 66.67%, rgba(0, 0, 0, 0) 100%);
      }

      @include media-breakpoint-up(xl) {
        padding: 24px 32px;
      }
    }

    .activism-tile-item__title {
      font-weight: $font-weight-bold;
      font-size: 28px;
      color: $white;
      //text-transform: uppercase;
      line-height: $line-height-normal;
      max-width: 240px;

      @include media-breakpoint-up(sm) {
        font-size: 36px;
        max-width: 360px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 28px;
        max-width: 240px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 36px;
        max-width: 360px;
      }
    }

    [data-component^="button-"] {
      margin: 0;
    }

    .text-button {
      margin: 8px 0;
    }
  }
}
