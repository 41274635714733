@import '../../../../styles/basics';

.bb-section-with-charts {
  @include bb-standalone-spacing();

  &__charts {
    // center one-column rows on mobile regardless of screensize
    margin: auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  &__item {
    padding-left: 20px;
  }
  &__button-wrapper {
    text-align: center;
  }
  &__button {
    margin-right: 0;
    margin-top: 34px;
  }

  @include media-breakpoint-up(md) {
    &__charts {
      max-width: none;
      flex-direction: row;
      align-items: flex-start;
      gap: 40px;
    }
    &__item {
      flex: 1 0 0;
      padding-left: 0;
    }

    &__title {
      margin-bottom: 60px !important;
    }
    &__button {
      margin-top: 60px;
    }
  }
}
