@import '../../../../../styles/basics';

.bb-hero-heading {
  &:global(.bb) {
    margin-top: 40px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 60px;
      margin-bottom: 40px;
    }
  }

  :global {
    .title {
      text-align: center;
      margin-bottom: 18px;
      font-size: 32px;
      line-height: $line-height-normal;

      @include media-breakpoint-up(md) {
        margin-bottom: 24px;
        font-size: 46px;
      }
    }

    .description {
      text-align: center;
      font-size: 18px;

      @include media-breakpoint-up(md) {
        font-size: 28px;
      }
    }
  }
}
