@import '../../../../styles/basics';

.centered-column-layout {
  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  & > :global(.wrapper) {
    @include media-breakpoint-up(md) {
      margin: auto;
      width: 83.33333%;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 80px;
      max-width: 960px;
    }

    :global {
      .bb > .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
