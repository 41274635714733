@import '../../../../styles/basics';

.bb-impact-stats {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    :global(.icon-stat) {
      flex-basis: 100%;

      &:last-child {
        flex-grow: 1;
      }

      p {
        max-width: 240px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    :global(.icon-stat) {
      flex-basis: 33%;
      justify-content: flex-start;
    }
  }
}
