@import '../../../../styles/basics';
@import '../../../../styles/mixins/buttons';

.bb-two-three-tiles {
  &:global(.bb) {
    @include bb-standalone-spacing-new();
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 2px;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 2px;
  }

  &__heading {
    margin-top: 32px;
    margin-bottom: 24px;
    text-align: center;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 0;
  }

  &__item {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;

    &:before {
      content: none;
    }
  }

  &__item-image {
    position: relative;
    aspect-ratio: 1/1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 33%, rgba(0, 0, 0, 0.00) 67%, rgba(0, 0, 0, 0.00) 100%);
    }
  }

  &__item-image-emergency {
    aspect-ratio: 1/1;
    background-color: $black;
    background-image: url('/static/icons/hero-social-ad-emergency-bg-shape.svg');
    background-size: 104% auto;
    background-position: right -625% bottom 160px;
    background-repeat: no-repeat;
  }

  &__item-info {
    position: absolute;
    left: 20px;
    bottom: 32px;
    max-width: 320px;

    [data-component^="button"] {
      margin: 0;
    }
  }

  &__item-title {
    margin-bottom: 10px;
    color: $white;
    font-size: 18px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    line-height: $line-height-large;
  }

  a {
    color: $black;
    display: block;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      // Use hover only when it is supported by a device.
      @media (hover: hover) and (pointer: fine) {
        [data-component="button-primary"] {
          @include button-primary-hover;
          @include button-with-arrow-hover;
        }

        [data-component="button-secondary"] {
          @include button-secondary-hover;
          @include button-with-arrow-hover;
        }

        [data-component="button-emergency"] {
          @include button-emergency-hover;
          @include button-with-arrow-hover;
        }
      }
    }
  }

  &__item-label {
    position: absolute;
    top: 32px;
    right: 20px;
  }

  // In this particular case using "down" breakpoints allows
  // to avoid double overriding for container.
  @include media-breakpoint-down(md) {
    :global(.container) {
      max-width: none;
    }
  }

  @include media-breakpoint-up(sm) {
    &__item-image,
    &__item-image-emergency {
      aspect-ratio: 16/9;
    }

    &__item-info {
      left: 36px;
    }

    &__item-label {
      right: 36px;
    }
  }

  @include media-breakpoint-up(md) {
    &__heading {
      font-size: 28px;
    }

    &__item-image,
    &__item-image-emergency {
      aspect-ratio: 768/329;
    }

    &__item-title {
      font-size: 24px;
      line-height: $line-height-normal;
    }
  }

  @include media-breakpoint-up(lg) {
    &__items {
      flex-direction: row;
    }

    &__item {
      flex: 1 1 0;
    }

    &__item-image,
    &__item-image-emergency {
      aspect-ratio: 1/1;
    }

    &__items--items-2 .bb-two-three-tiles__item-image,
    &__items--items-2 .bb-two-three-tiles__item-image-emergency {
      aspect-ratio: 3/2;
    }

    &__items--items-4 .bb-two-three-tiles__item-image,
    &__items--items-4 .bb-two-three-tiles__item-image-emergency {
      aspect-ratio: 358/464;
    }

    &__item-info {
      max-width: 240px;
      left: 32px;
    }

    &__items--items-4 .bb-two-three-tiles__item-info {
      max-width: none;
      right: 32px;
    }

    &__item-title {
      font-size: 18px;
      margin-bottom: 24px;
    }

    &__item-label {
      right: 32px;
    }
  }

  @include media-breakpoint-up(xl) {
    &__heading {
      font-size: 36px;
      margin-top: 56px;
    }

    :global(.container) {
      & > h2 {
        margin-bottom: 40px;
      }
    }

    &__item-info {
      max-width: 320px;
    }

    &__items--items-4 .bb-two-three-tiles__item-info {
      max-width: none;
    }

    &__item-title {
      font-size: 22px;
      line-height: $line-height-large;
    }
  }

  @media (min-width: 1840px) {
    &__item-info {
      left: 56px;
      bottom: 48px;
      max-width: 480px;
    }

    &__items--items-4 .bb-two-three-tiles__item-info {
      max-width: none;
      right: 56px;
    }

    &__item-title {
      font-size: 36px;
      line-height: $line-height-normal;
    }

    &__item-label {
      top: 48px;
      right: 56px;
    }
  }

}
