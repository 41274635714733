@import '../../../../styles/basics';


// The base component class name.
$component-name: 'bb-image-grid';

.#{$component-name} {
  @include bb-standalone-spacing();

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__cols {
    &--col {
      padding-left: 6px;
      padding-right: 6px;
      margin-bottom: 12px;
    }

    &--col-12 {
      width: 100%;
    }

    &--col-6 {
      width: 50%;
    }
  }

  &__caption {
    color: $white;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    font-size: 16px;
    line-height: $line-height-normal;
    z-index: 15;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &__row {
    :global {
      .item {
        position: relative;
        height: 100%;

        &.clickable {
          cursor: pointer;
        }

        &:after {
          background: linear-gradient(to bottom, rgba(33, 36, 49, 0), $slate-1200);
          content: ' ';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 70%;
          z-index: 10;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  // Configure padding-top for inline_image body blocks
  // to avoid CLS issues.
  // TODO: Change it when new solution will be available for picture tag.
  &:global(.bb-inline-image),
  &:global(.bb-inline-image-twoColumns) {
    :global {
      .item {
        @media all and (min-resolution: 1x) {
          img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
          }
          // 360x220 image. 220 / 360 * 100 ~ 61.111
          padding-top: 61.111%;
        }

        @media all and (min-resolution: 2x) {
          // 560x290 image. 290 / 560 * 100 ~ 51.785
          padding-top: 51.785%;
        }

        @media all and (min-width: 576px) and (min-resolution: 1x) {
          // 560x290 image. 290 / 560 * 100 ~ 51.785
          padding-top: 51.785%;
        }

        @media all and (min-width: 576px) and (min-resolution: 2x) {
          // 700x400 image. 400 / 700 * 100 ~ 57.143
          padding-top: 57.143%;
        }

        @media all and (min-width: 768px) and (min-resolution: 1x) {
          // 700x400 image. 400 / 700 * 100 ~ 57.143
          padding-top: 57.143%;
        }

        @media all and (min-width: 768px) and (min-resolution: 2x) {
          // 1120x630 image. 630 / 1120 * 100 ~ 56.25
          padding-top: 56.25%;
        }
      }
    }
  }

  &:global(.bb-inline-image) {
    :global {
      .item {
        @media all and (min-width: 992px) and (min-resolution: 1x) {
          // 1120x630 image. 630 / 1120 * 100 ~ 56.25
          padding-top: 56.25%;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__cols {
      &--col-md-12 {
        width: 100%;
      }

      &--col-md-6 {
        width: 50%;
      }

      &--col-md-4 {
        width: 33.33333333%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    :global(.has-caption):hover {
      .#{$component-name}__caption,
      &:global(.item):after {
        opacity: 1;
      }
    }
  }
}

.image-grid-slider {
  :global {
    .inner {
      position: relative;
      margin: auto;
      width: 100%;
      max-width: 1200px;
      max-height: 600px;

      .item {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        -moz-transition: opacity 0.5s;
        -ms-transition: opacity 0.5s;
        -o-transition: opacity 0.5s;
        transition: opacity 0.5s;
        @include media-breakpoint-up(lg) {
          left: 80px;
          right: 80px;
        }

        &.active {
          opacity: 1;
          position: static;
          @include media-breakpoint-up(lg) {
            padding: 0 80px;
          }
        }

        img {
          width: 100%;
          height: 55vh;
          object-fit: contain;
          @include media-breakpoint-up(lg) {
            height: 75vh;
            max-height: 600px;
          }
        }

        .caption {
          margin: 16px 20px;
          font-size: 14px;
          color: $white;
          @include media-breakpoint-up(lg) {
            margin: 16px 0 0;
          }
        }
      }

      .navigation {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;

        .counter {
          font-weight: $font-weight-bold;
          font-size: 14px;
          color: $white;
          margin: 0 20px;
        }

        .arrow {
          position: relative;
          width: 44px;
          height: 44px;
          z-index: 300;
          cursor: pointer;
          bottom: -22px;
          display: inline-block;
          background: none;
          border: none;

          svg {
            top: 3px;
            position: relative;
          }

          &:focus,
          &:focus-visible,
          &:focus-within {
            //outline: 2px solid $deep-blue-500;
            border-radius: 100%;
          }

          @include media-breakpoint-up(lg) {
            bottom: auto;
          }

          &:hover,
          &:focus {
            svg * {
              stroke: $biscuit-200;
            }
          }

          circle,
          path {
            transition: stroke 0.2s;
          }

          &.prev {
            transform: translateY(-22px);
            svg {
              transform: rotate(180deg);
            }

            @include media-breakpoint-up(lg) {
              left: 12px;
            }
          }

          &.next {
            transform: rotate(360deg) translateY(-22px);

            @include media-breakpoint-up(lg) {
              left: calc(100% - 100px);
            }
          }

          &.disabled {
            visibility: hidden;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    :global(.inner .navigation) {
      position: absolute;
      display: block;
      top: 50%;
    }

    :global(.counter) {
      display: none;
    }
  }
}
