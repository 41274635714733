@import '../../../styles/basics';
@import '../../../styles/mixins/buttons';

.tile-item {
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;

  &:before {
    display: none;
  }

  :global {
    .tile-item-container {
      display: block;
      width: 100%;
      position: relative;
      margin: auto;
      overflow: hidden;

      // Use hover only when it is supported by a device.
      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus-visible {
          .tile-item-background {
            transform: scale(1.03);
          }

          [data-component="button-primary"] {
            @include button-primary-hover;
            @include button-with-arrow-hover;
          }

          [data-component="button-secondary"] {
            @include button-secondary-hover;
            @include button-with-arrow-hover;
          }
        }
      }

      .tile-item-background {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: 0.2s all;

        img {
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0)
          );

          @include media-breakpoint-up(md) {
            background: linear-gradient(
              to top,
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0)
            );
          }
        }
      }

      .tile-item-box {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 20px 12px;
        gap: 12px;

        @include media-breakpoint-up(sm) {
          padding: 20px;
        }

        @include media-breakpoint-up(xl) {
          padding: 24px 32px;
        }

        .tile-badge {
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }

      .tile-item-title {
        font-weight: $font-weight-bold;
        font-size: 18px;
        color: $white;
        text-transform: uppercase;
        line-height: $line-height-normal;
        max-width: 240px;

        @include media-breakpoint-up(sm) {
          font-size: 24px;
          max-width: 320px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          max-width: 240px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 24px;
          max-width: 320px;
        }
      }

      [data-component^="button-"] {
        margin: 0;
      }
    }
  }
}
