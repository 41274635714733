@import '../../../../styles/basics';
@import '../../../../styles/mixins/hero';
@import '../../../../styles/mixins/responsive-image';
@value field-type-radios-button__radios, field-type-radios-button__radios-item from '../../../01_atoms/FormElements/RadiosButton/index.module.scss';

// UTILITY RESET CLASS.
// Appeal hero block uses fixed background that has to be reset by this class.
.reset-after-appeal-hero {
  @include media-breakpoint-up(lg) {
    background-color: $white;
    padding-bottom: 40px;
    margin-bottom: -40px;
    overflow: auto;
  }
}

// Reusable block that is used on both appeal and donation checkout pages.
// Changes made to this block are applied to both pages, so be carefully editing it.
.appeal-hero-with-money-handles {
  margin-top: 0;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: 600px;
  }

  .form-card {
    [data-component="button"] {
      margin-right: 0;
    }
  }

  :global {
    .large {
      line-height: $line-height-normal;
      font-size: 24px;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 32px;
      }
    }

    // MAIN FORM CONTAINER.
    .form-card {
      padding: 16px 0;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
      .form-card {
        padding: 46px;
        min-height: 620px;
        width: 600px;
        margin-left: 0;
        margin-right: 0;
        border-radius: 16px;
        background-color: $biscuit-50;
        box-shadow: 0 1px 30px rgba(33, 36, 49, 0.3);
      }
    }

    // SHAPED BACKGROUND ON DESKTOP.
    .shaped-background {
      position: fixed;
      top: 0;
      height: 100vh;
      width: 100%;
      z-index: -20;
      background-color: $turquoise-1200;

      div {
        position: fixed;
        top: 0;
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .image {
        z-index: -20;
        right: 0;
        width: 66%;
        background-position: left 10%;
      }

      .shape {
        z-index: -19;
        left: 0;
        width: 50%;
        background-image: url('/static/icons/checkout-shape.svg');
        background-position: right center;
      }
    }
  }

  &--emergency,
  &--dec {
    :global {
      .shaped-background {
        background-color: $slate-1200;

        .shape {
          background-image: url('/static/icons/checkout-shape-emergency.svg');
        }

        &.no-image {
          background-image: url('/static/icons/checkout-emergency-full-width.png');
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;

          .shape {
            background-image: none;
            display: none;
          }
        }
      }
    }
  }

  &--dec {
    position: relative;

    :global {
      .cover-image {
        position: relative;
      }

      @include hero-dec();

      // Overwrite DEC styles.
      .red-strip {
        width: 70px;
        background-image: linear-gradient(
          to bottom,
          rgba(228, 17, 17, 0.8),
          rgba(228, 17, 17, 0.8)
        );
        mix-blend-mode: normal;
      }

      .watermark {
        width: 50px;
        right: 10px;
      }

      @include media-breakpoint-up(md) {
        .red-strip {
          background-image: linear-gradient(
            to bottom,
            rgba(228, 17, 17, 0.95),
            rgba(228, 17, 17, 0.8),
            rgba(228, 17, 17, 0.6),
            rgba(0, 0, 0, 0)
          );
          width: 110px;
          mix-blend-mode: normal;
        }

        .watermark {
          width: 76px;
          right: 17px;
        }
      }

      @include media-breakpoint-up(lg) {
        .red-strip {
          background-image: linear-gradient(
            to bottom,
            rgba(228, 17, 17, 0.95),
            rgba(228, 17, 17, 0.8),
            rgba(228, 17, 17, 0.1),
            rgba(0, 0, 0, 0)
          );
        }
      }
    }
  }
}

.bb-appeal-hero-with-money-handles {
  :global {
    // MAIN FORM CONTAINER.
    .form-card {
      text-align: center;
    }

    // RADIO BUTTONS.
    .field-donation_type,
    .field-money_handle_single_donation,
    .field-money_handle_recurring_donation {
      margin-top: 18px;
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        margin-top: 26px;
        margin-bottom: 26px;
      }

      > label {
        font-weight: $font-weight-semi-bold;
        font-size: 18px;
        margin-bottom: 16px;
        @include media-breakpoint-up(md) {
          font-size: 22px;
          margin-bottom: 22px;
        }
      }
    }

    [data-component^="button"] {
      margin-right: 0;
      margin-bottom: 0;
    }

    .field-donation_type,
    .donation-type,
    .field-money_handle_single_donation .field-type-radios-button__radios,
    .field-money_handle_recurring_donation .field-type-radios-button__radios {
      width: 100%;
      max-width: 347px;
      margin-left: auto;
      margin-right: auto;
    }

    .donation-type {
      position: relative;
      margin-top: 14px;
      margin-bottom: -4px;

      @include media-breakpoint-up(md) {
        margin-top: 18px;
        margin-bottom: -8px;
      }

      span {
        position: relative;
        padding: 0 16px;
        background-color: $white;
        font-size: 18px;

        @include media-breakpoint-up(lg) {
          background-color: $biscuit-50;
        }
      }

      &:before {
        top: 50%;
        left: 0;
        width: 100%;
        border-bottom: 2px solid $turquoise-100;
        content: '';
        position: absolute;
        pointer-events: none;
      }
    }

    .field-money_handle_single_donation,
    .field-money_handle_recurring_donation {
      .field-type-radios-button__radios-item,
      [data-component="field-type-radios-button"],
      [data-component="field-type-radios-button--selected"] {
        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    // CSS ARROW FOR SELECTED IMPACT DESCRIPTION BLOCK.
    // See https://cssarrowplease.com
    .show-impact-arrow {
      position: relative;

      &:after,
      &:before {
        bottom: -20px;
        @include media-breakpoint-up(md) {
          bottom: -26px;
        }
        left: 50%;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: transparent;
        border-bottom-color: $turquoise-100;
        border-width: 12px;
        margin-left: -12px;
      }
      &:before {
        border-color: transparent;
        border-bottom-color: $turquoise-100;
        border-width: 13px;
        margin-left: -13px;
      }
    }

    // AMOUNT FIELD.
    .field-amount-label {
      font-size: 18px;
      margin-top: 0;
      @include media-breakpoint-up(md) {
        bottom: -26px;
      }
    }
    .field-type-text {
      width: 126px;
      margin-left: auto;
      margin-right: auto;
      input {
        padding-right: 10px;
      }

      [data-component="field-error"] {
        white-space: nowrap;
      }
    }

    // RESPONSIVE IMAGES FOR MOBILE & TABLET.
    @include responsive-image();
  }

  &__bottom-description {
    max-width: 402px;
    margin: 16px auto 0;
    font-size: 16px;
  }

  &__monthly-info {
    margin: 30px -20px -16px;
    padding: 16px 24px;
    background: $slate-100;
    font-size: 14px;
  }

  &__monthly-info-text {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up(sm) {
    &__monthly-info {
      margin-left: auto;
      margin-right: auto;
      max-width: 510px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__monthly-info {
      margin: 32px -46px -46px;
      max-width: none;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  &.appeal-hero-with-money-handles--emergency,
  &.appeal-hero-with-money-handles--dec {
    :global {
      .show-impact-arrow {
        &:after {
          border-bottom-color: $slate-100;
        }

        &:before {
          border-bottom-color: $slate-100;
        }
      }

      .donation-type {
        &:before {
          border-bottom-color: $slate-200;
        }
      }
    }
  }
}
