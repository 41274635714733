@import '../../../../styles/basics';

.bb-sidebar-toc {
  transition: 0.2s opacity;

  nav {
    margin-inline: auto;
    max-width: 540px;
    padding-left: 20px;
    padding-top: 32px;

    @include media-breakpoint-up(md) {
      max-width: 720px;
      padding-left: 12px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 960px;
    }
    @include media-breakpoint-down(xl) {
      // Overwrite the margin set on the "style" attribute by the js code.
      margin-bottom: 0 !important;

      a:hover, a:focus {
        padding-inline: 10px;
        margin-left: -10px;
      }
    }
  }

  li {
    font-size: 16px;
    padding: 0;
    margin-bottom: 0;
    &::before {
      display: none;
    }
  }
  a {
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 12px;
    color: $slate-1200;
    text-decoration: underline;
    text-decoration-color: $slate-1000;
    transition: 0.2s all;
    text-underline-offset: 2px;

    &:hover {
      color: $turquoise-1200;
      background-color: $turquoise-100;
      text-decoration-color: $turquoise-1200;
    }

  }

  h4 {
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  @include media-breakpoint-up(xl) {
    // On initial render we set height to 0. Then on JS level we set it to auto
    // after calculating real block height.
    height: 0;
    position: sticky;
    z-index: 100;
    top: 80px;
    width: auto;
    max-width: 350px;
    margin-top: 40px;
    margin-left: calc((100vw - 1280px)/2 + 1280px * 0.75);

    nav {
      background-color: transparentize($white, 0.1);
      padding: 16px;
    }

    li {
      margin-bottom: 8px;
    }

    a {
      padding: 4px 8px;
      transition: 0.2s all;

      &.active {
        color: $white;
        background-color: $turquoise-1200;
        text-decoration-color: $white;
      }
    }

  }

  @media (min-width: 1600px) {
    max-width: 400px;
    margin-left: calc((100vw - 1280px)/2 + 1280px * 0.82);
  }
}

.bb-sidebar-toc-hidden {
  visibility: hidden;
  opacity: 0;
}
