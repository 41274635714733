@import '../../../../styles/basics';
@import '../../../../styles/mixins/buttons';

.block {
  &:global(.bb) {
    @include bb-standalone-spacing-new();
  }

  :global(.container) {
    // Give the block more space on smaller desktops.
    @media (min-width: 1200px) and (max-width: 1300px) {
      padding: 0 32px;
    }
  }

  .image {
    display: block;
    overflow: hidden;
    background-color: $turquoise-800;

    img {
      width: 100%;
    }
  }

  a {
    color: $black;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      // Use hover only when it is supported by a device.
      @media (hover: hover) and (pointer: fine) {
        [data-component="button-primary"] {
          @include button-primary-hover;
          @include button-with-arrow-hover;
        }

        [data-component="button-secondary"] {
          @include button-secondary-hover;
          @include button-with-arrow-hover;
        }

        img {
          transform: scale(1.03);
        }
      }
    }
  }

  // Adjustments for Activism page type.
  &:global(.variant-activism) {
    &:global(.bb) {
      @include bb-activism-spacing();
    }

    &:global(.behaviour-settings__background-color--yellow) {
      position: relative;
      padding-top: 48px;
      padding-bottom: 48px;

      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $yellow-800;
        border-radius: 24px;
        z-index: -1;
      }

      @include media-breakpoint-up(sm) {
        &:before {
          left: 15px;
          right: 15px;
        }
      }

      @include media-breakpoint-up(lg) {
        padding-top: 88px;
        padding-bottom: 88px;

        &:before {
          border-radius: 40px;
        }
      }
    }

    .heading {
      font-size: 32px;
      margin-bottom: 40px;

      @include media-breakpoint-up(lg) {
        font-size: 60px;
        margin-bottom: 64px;
      }
    }

    li {
      [data-component^="button"],
      [data-component="text-button"] {
        margin: 0;
      }
    }

    li.featured {
      .image {
        aspect-ratio: 330/320;
        position: relative;
        border-radius: 4px;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @include media-breakpoint-up(md) {
          aspect-ratio: 709/449;
        }
      }

      .info {
        padding: 16px;
        border-radius: 4px;
        gap: 16px;

        @include media-breakpoint-up(sm) {
          padding: 24px;
          gap: 24px;
        }

        @include media-breakpoint-up(lg) {
          padding: 32px;
          gap: 32px;
          background: linear-gradient(66.23deg, rgba(0, 0, 0, 0.4) 10.14%, rgba(0, 0, 0, 0) 100%);
        }
      }

      .title {
        font-size: 28px;
        text-transform: none;
        max-width: 280px;
        line-height: $line-height-normal;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
          font-size: 36px;
          max-width: 360px;
        }

        @include media-breakpoint-up(md) {
          font-size: 36px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 60px;
          line-height: $line-height-small;
          max-width: 440px;
        }
      }
    }

    li.normal {
      border-color: $gray-1200;

      &:last-child {
        border: none;
      }

      .image {
        border-radius: 4px;
      }

      .title {
        text-transform: none;
        font-size: 22px;
        line-height: $line-height-large;
        margin-bottom: 8px;
        color: $gray-1200;

        @include media-breakpoint-up(xl) {
          font-size: 22px;
          line-height: $line-height-large;
        }

        @media (min-width: 1400px) {
          font-size: 36px;
          line-height: $line-height-normal;
        }
      }
    }

    // Use hover only when it is supported by a device.
    @media (hover: hover) and (pointer: fine) {
      a {
        &:hover,
        &:focus-visible {
          :global(.text-button--default) {
            @include text-button-default-hover;
            @include text-button-with-arrow-hover;
          }
        }
      }
    }
  }
}

.heading {
  text-align: center;
  color: $black;
}

.ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;

  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: 709px auto;
    column-gap: 16px;
  }

  li {
    margin: 0;
    padding: 0;

    &::before {
      all: revert;
    }
  }
}

.title {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  line-height: $line-height-normal;
  font-size: 18px;
  margin-bottom: 12px;
}


li.featured {
  position: relative;
  margin-bottom: 16px;

  @include media-breakpoint-up(xl) {
    grid-row: 1/4;
    margin-bottom: 0;
    width: 709px;
    height: 449px;
  }

  a {
    display: block;
  }

  .image {
    aspect-ratio: 80/51;

    @include media-breakpoint-up(md) {
      aspect-ratio: 709/449;
    }
  }

  .info {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    padding: 0 12px 4px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background: linear-gradient(19.8deg, transparentize($slate-1200, 0.22) 10.28%, transparentize($slate-1200, 1) 94.43%);

    @include media-breakpoint-up(md) {
      padding: 0 12px 30px 38px;
    }
  }

  .title {
    color: $white;
    max-width: 85%;

    @include media-breakpoint-up(md) {
      font-size: 28px;
    }
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 12px;
    @include media-breakpoint-up(md) {
      top: 16px;
      right: 16px;
    }
  }
}

li.normal {
  position: relative;
  border-bottom: 1px dotted $turquoise-800;
  padding-top: 12px;
  padding-bottom: 11px;

  &:nth-child(2){
    padding-top: 0;
  }

  &:last-child {
    align-self: end;

    @include media-breakpoint-up(lg) {
      border: none;
      padding-bottom: 0;
    }
  }

  .image {
    aspect-ratio: 61/56;
    min-width: 122px;

    @include media-breakpoint-up(md) {
      aspect-ratio: 205/133;
      min-width: 205px;
      width: 205px;
      margin-bottom: 0;
    }
  }

  a {
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
  }

  .info {
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 16px;

    [data-component^="button"] {
      margin: 0;
    }
  }

  .title {
    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }
}
