@import '../../../../styles/basics';

.search-touched {
  :global {
    .bb {
      display: none;
    }
    .bb-hub-search {
      display: block;
    }
  }
}

.search-not-touched {
  :global {
    .hide-on-hub-homepage {
      display: none !important;
    }
  }
}
