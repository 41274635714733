@import '../../../styles/basics';

.badge {
  font-weight: $font-weight-bold;
  line-height: $line-height-normal;
  color: $black;
  font-size: 18px;
  background: $yellow-800;
  max-width: 96px;
  text-transform: uppercase;
  padding: 0 4px;

  @include media-breakpoint-up(md) {
    max-width: none;
  }
}

.asSeenOnTV {
  background-image: url('/static/textures/label-backgroound-as-seen-on-tv.svg');
  background-repeat: no-repeat;
  width: 83px;
  height: 40px;
  background-size: 100%;
  font-weight: $font-weight-bold;
  color: $black;
  font-size: 18px;

  &:before {
    content: 'AS SEEN';
    position: absolute;
    top: -2px;
    left: 5px;
    width: 100%;
  }

  &:after {
    content: 'ON TV';
    position: absolute;
    top: 17px;
    left: 4px;
  }
}
