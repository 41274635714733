@import '../../../../styles/basics';

.activism {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: -50px;
  background: radial-gradient(
                  90.97% 418.98% at 79.79% 49.96%,
                  rgba(217, 243, 249, 0.71) 0%,
                  rgba(217, 243, 249, 0) 100%
  ) , $white;
  mix-blend-mode: normal;

  @include media-breakpoint-up(xl) {
    margin-top: -75px;
  }
}
