@import '../../../../styles/basics';

.item-with-image-hoverable-small {
  :global {
    .item {
      display: block;
      position: relative;
      overflow: hidden;
      color: $white;

      @include media-breakpoint-up(md) {
        &:hover {
          .hoverable {
            top: 0;
          }

          .more-info-link,
          .description,
          .hoverable-bg {
            opacity: 1;
          }
        }
      }

      img {
        width: 100%;
      }

      .hoverable {
        padding: 0 12px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $turquoise-1000;
        z-index: 1;
        width: 100%;
        @include media-breakpoint-up(md) {
          padding: 0 30px;
          top: calc(100% - 60px);
          height: 100%;
          transition: top 0.3s ease-in-out;
        }

        .more-info-link,
        .description,
        .title {
          color: $white;
        }
      }

      .title {
        margin: 6px 0;
        font-size: 14px;
        @include media-breakpoint-up(md) {
          margin-top: 18px;
          font-size: 20px;
          line-height: $line-height-small;
        }
      }
      .description {
        opacity: 0;
        transition: opacity 0.65s ease-in-out;
        font-size: 16px;
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
        }
        @include media-breakpoint-up(lg) {
          font-size: 14px;
        }
        @include media-breakpoint-up(xl) {
          font-size: 16px;
        }
      }
      .more-info-link {
        color: $white;
        opacity: 0;
        position: absolute;
        bottom: 20px;
        left: 30px;
        font-size: 18px;
        transition: opacity 0.8s ease-in-out;
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
        }
        @include media-breakpoint-up(lg) {
          font-size: 16px;
        }
        @include media-breakpoint-up(xl) {
          font-size: 18px;
        }
      }
      .hoverable-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $turquoise-1000;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: -1;
      }
    }
  }
}
