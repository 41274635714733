@import '../../../../styles/basics';
@import '~bootstrap/scss/mixins/grid';

.left-column-layout {
  margin-left: auto;
  margin-right: auto;
  padding: 0;

  // Default grid.
  .bb-grid-default {
    @include media-breakpoint-up(xl) {
      @include make-col-ready();
      @include make-col(8);
      padding-left: 0 !important;
      display: flow-root;
    }
  }

  .bb-grid-heading {
    @include media-breakpoint-up(md) {
      margin-bottom: -20px;
    }
  }

  .bb-grid-9 {
    @include media-breakpoint-up(xl) {
      @include make-col(9);
    }
  }

  @media (min-width: 1600px) {
    .bb-grid-quote {
      @include make-col(7);
      @include make-col-offset(1);
    }
  }

}
