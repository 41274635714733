@import '../../../styles/basics';
@import '../../../styles/mixins/buttons';

.text-button {
  font-weight: $font-weight-bold;
  display: inline-block;
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  padding: 0;
  color: $gray-1200;
  transition: 0.3s all;
  position: relative;
  margin: 0 16px 16px 0;
  text-align: center;

  &:hover,
  &.hover,
  &:focus-visible {
    @include text-button-default-hover;
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
  }

  &--small {
    font-size: 14px;
    line-height: 16px;
  }

  &--large {
    font-size: 18px;
    line-height: 30px;
  }

  &--extra-large {
    font-size: 16px;
    text-transform: uppercase;
  }

  &--with-arrow {
    white-space: nowrap;

    svg {
      position: relative;
      margin-left: 4px;
      transition: 0.3s all;
      right: 0;
      margin-bottom: 4px;
      fill: $pink-800;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      @include text-button-with-arrow-hover;
    }

    a:hover & path {
      fill: $pink-800;
    }
  }

  &--light {
    color: $white;

    &:hover,
    &.hover,
    &:focus-visible {
      @include text-button-light-hover;
    }

    &.text-button--with-arrow {
      svg {
        fill: $white;
      }

      a:hover & path {
        fill: $white;
      }
    }
  }
}
